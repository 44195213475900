const domains = {
  // TODO: add demo domain
  local: "http://middleman.test",
  stage: "https://staging-api.hubins.com",
  development: "https://staging-api.hubins.com",
  beta: "https://beta-api.hubins.com",
  production: "https://api.hubins.se",
};

const getBaseUrl = (stage) => {
  return `${domains[stage]}/api`;
};

const getCsrfRoute = (stage) => {
  return `${import.meta.env.VITE_MIDDLEMAN_URL}/sanctum/csrf-cookie`;
};
const stage = import.meta.env.MODE;
const base_url = getBaseUrl(stage);
const csrf_url = getCsrfRoute(stage);
// const base_url = import.meta.env.VITE_MIDDLEMAN_URL;
// const csrf_url = import.meta.env.VITE_MIDDLEMAN_URL + "/sanctum/csrf-cookie";

// Print logs good for developers
if (
  (stage === "development" || stage === "stage") &&
  String.prototype.padStart &&
  !import.meta.env
) {
  const status = ` Platform: HUBINS | base_url: ${base_url}`;
  console.log(
    `%c${"".padStart(status.length, "*")}\n` +
      `%c${status}` +
      `%c\n${"".padStart(status.length, "*")}`,
    "background: #030865; color: #030865",
    "background: #030865; color: #fdc6c8",
    "background: #030865; color: #030865"
  );
}

export default {
  base_url,
  csrf_url,
};
